import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import api, { DonationSubmission } from "@/api"

export enum DonationResult {
  SUCCESS,
  ERROR,
}

const useDonationPerformer = () => {
  const stripe = useStripe()
  const stripeElements = useElements()

  if (!stripe || !stripeElements) {
    return () => {
      return Promise.resolve(null)
    }
  }

  return async (donationSubmission: Omit<DonationSubmission, "paymentMethod">) => {
    const stripeElement = stripeElements.getElement(CardElement)

    if (!stripeElement) {
      throw new Error("Cannot find credit card input element in DOM descendants")
    }

    const { token: paymentMethod, error: stripeError } = await stripe.createToken(stripeElement)

    if (stripeError) {
      console.error("Error saving credit card information:", stripeError)
      return DonationResult.ERROR
    }

    const { error, donationResult } = await api.donate({
      paymentMethod,
      ...donationSubmission,
    })

    if (error) {
      console.error("Error in api.donate response")
      return DonationResult.ERROR
    }

    if (donationResult.status === "requires_action") {
      if (!donationResult.client_secret) {
        console.error("No client secret received")
        return DonationResult.ERROR
      }

      const { error } = await stripe.handleNextAction({
        clientSecret: donationResult.client_secret,
      })

      if (error) {
        console.error("ERROR from Stripe:", error)
        return DonationResult.ERROR
      }
    }

    return DonationResult.SUCCESS
  }
}

export default useDonationPerformer
