import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

const SmallPrint = styled.div`
  ${globalStylesCss};

  width: 100%;
  text-align: center;

  font-size: var(--font-size-primary);
  letter-spacing: 1px;
  color: var(--color-gray-48);
`

export default SmallPrint
