import React from "react"
import Popup from "@/ui/layout/Popup"
import styled from "styled-components"
import PaymentForm from "@/payments/PaymentForm"
import Subtitle from "@/ui/text/Subtitle"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"
import { forMobileOnly } from "@/ui/styles/breakpoints"
import { Link } from "react-router-dom"
import { gql, useMutation } from "@apollo/client"
import { Token } from "@stripe/stripe-js"
import { Dispatch } from "redux"
import { connect } from "react-redux"
import UserSession from "@/userSession"
import { useHistory } from "react-router"
import { welcomeRoute } from "../../constants"
import analytics from "@/analytics"

interface ReactivationPopupProps {
  isOpen: boolean
  onClose: () => void
  userId: number
  dispatchOnReactivationSuccess: () => void
}

const Instructions = styled.div`
  ${globalStylesCss};

  margin-bottom: 1rem;

  & ul {
    padding-left: 1em;
    text-align: left;
  }

  & li {
    line-height: normal;
    margin: 1em 0;
    font-size: var(--font-size-medium);

    @media (${forMobileOnly}) {
      font-size: var(--font-size-small);
    }
  }

  & a {
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

// TODO
// * gql queries wrapper
// * rewrite context with logged in user
// * refactor everything inside a reactivation hook
// * get rid of Redux / SET_AS_ARTIST machinery
// * fix storybook story with redux scaffolding
// * after reactivating through this form using paypal, user settings doesn't show user as artist, but on refresh it works

const ReactivationPopup = ({
  isOpen,
  onClose,
  userId,
  dispatchOnReactivationSuccess,
}: ReactivationPopupProps) => {
  const UpdatePaymentMethod = gql`
    mutation UpdatePaymentMethod(
      $id: ID!
      $token: String
      $cardType: String
      $last4: Int
      $paypalId: String
    ) {
      updatePaymentMethod(id: $id, token: $token, cardType: $cardType, last4: $last4, paypalId: $paypalId) {
        user {
          id
          paypalId
        }
        activePaymentMethod {
          paypalId
          last4
          cardType
        }
      }
    }
  `

  const accountSettingsQuery = gql`
    query MembershipSettingsQuery($id: ID!) {
      user(id: $id) {
        id
        createdAt
        paypalId
        activePaymentMethod {
          last4
          cardType
          paypalId
          coupon {
            id
            percentOff
            durationInMonths
            duration
            expiration
          }
        }
      }
    }
  `

  const [reactivateUser] = useMutation(UpdatePaymentMethod)

  const { push } = useHistory() // TODO Update react-router(-dom) to v6

  const finalizeReactivation = () => {
    analytics.emitEvent("User Reactivated")

    dispatchOnReactivationSuccess()
    UserSession.set("role", "artist")
    onClose()
    push(welcomeRoute)
  }

  const handleStripeReactivation = async (token: Token) => {
    await reactivateUser({
      variables: {
        id: userId,
        token: token.id,
        cardType: token.card?.brand,
        last4: parseInt(token.card?.last4 || ""),
        paypalId: null,
      },
      refetchQueries: [
        {
          query: accountSettingsQuery,
          variables: { id: userId },
        },
      ],
    })

    return finalizeReactivation
  }

  const handlePaypalReactivation = async (subscriptionId: string) => {
    await reactivateUser({
      variables: { id: userId, token: null, cardType: null, last4: null, paypalId: subscriptionId },
      refetchQueries: [
        {
          query: accountSettingsQuery,
          variables: { id: userId },
        },
      ],
    })

    return finalizeReactivation
  }

  return (
    <Popup open={isOpen} onClose={onClose}>
      <Subtitle>Welcome back to Foundwork</Subtitle>
      <Instructions>
        <ul>
          <li>
            Please re-enter your credit or debit card or use PayPal to reactivate your artist account. This
            will reactivate your Foundwork subscription starting today.
          </li>
          <li>
            If you don't want to reactivate your artist account at this time, you can do so later on in your
            account settings.
          </li>
          <li>
            If you have any questions, please see our{" "}
            <Link to="/faq" target="_blank">
              FAQ
            </Link>
            .
          </li>
        </ul>
      </Instructions>
      <PaymentForm
        enableCoupon={false}
        submitMessage="Subscribe and reactivate account"
        onPayPalSubscriptionCreated={handlePaypalReactivation}
        onCreditCardTokenAcquired={handleStripeReactivation}
      />
    </Popup>
  )
}

const mapStateToProps = ({ session }: { session: { userId: number } }) => {
  const { userId } = session

  return { userId }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchOnReactivationSuccess: () => {
    dispatch({ type: "SET_AS_ARTIST", payload: {} })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ReactivationPopup)
