import React, { Component } from "react"
import { connect } from "react-redux"
import { push } from "connected-react-router"
import { validateCredentials } from "../../../services/auth"
import sendEvent from "../../../services/tracker"
import { actions } from "../../../actions.js"
import { loginRoute, signupFields, subscriptionRoute } from "../../../constants"
import EntryForm from "../../../components/EntryForm"
import LoginFlowWrapper from "../../../components/LoginFlowWrapper"
import LoginFlowPrompt from "../../../components/LoginFlowPrompt"
import SignupTerms from "../../../components/SignupTerms"

class SignupForm extends Component {
  componentDidMount() {
    document.querySelector("html").classList.remove("no-scroll")
  }

  componentWillUnmount() {
    // Reset form in case they switch to other auth page
    this.props.resetForm()
  }

  handleSubmit(form) {
    const { createArtist } = this.props

    createArtist(form)
  }

  render() {
    const { error, loading } = this.props

    return (
      <div>
        <LoginFlowWrapper
          title={"Sign Up"}
          buttonText={"Sign Up"}
          onSubmit={this.handleSubmit.bind(this)}
          error={error}
          loading={loading}
        >
          <EntryForm fields={signupFields} />
          <SignupTerms />
        </LoginFlowWrapper>

        <LoginFlowPrompt text={"Already have an account? "} url={loginRoute} linkText={"Log In"} />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { error, loading } = state.authForm
  return { error, loading }
}

const mapDispatchToProps = (dispatch) => {
  const {
    requestAuthFormSubmit,
    receiveAuthFormSubmit,
    authFormSubmitFailure,
    setFunnelData,
    resetAuthForm,
  } = actions

  return {
    resetForm: () => {
      dispatch(resetAuthForm())
    },
    createArtist: (form) => {
      dispatch(requestAuthFormSubmit())
      validateCredentials(form, ({ err, response }, data) => {
        if (err || !response.ok) {
          const errors = JSON.parse(response.text)
            .map((err) => {
              return err.message
            })
            .join(". ")
          dispatch(authFormSubmitFailure({ error: errors }))
          return
        }
        sendEvent("send", "event", {
          eventCategory: "Button",
          eventAction: "click",
          eventLabel: "Signup Submit",
          isArtist: true,
        })
        dispatch(
          setFunnelData({
            type: "user",
            data,
          })
        )
        dispatch(receiveAuthFormSubmit())
        dispatch(push(subscriptionRoute))
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignupForm)
