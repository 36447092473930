// @ts-ignore
import camelcaseKeys from "camelcase-keys"
import snakecaseKeys from "snakecase-keys"
import RequestBuilder from "@/network/RequestBuilder"
import request from "superagent"
import { PaymentIntent, Token } from "@stripe/stripe-js"

export interface CouponResponse {
  isValid: boolean
  isFree: boolean
  isForever: boolean
}

export interface DonationSubmission {
  firstName: string
  lastName: string
  email: string
  donationAmount: number
  paymentMethod: Token
}

export interface SectionResponse {
  content: string
  id: string
  image: {
    id: string
    url: string
    description: string
    title: string
  }
  sections: [SectionResponse]
  slug: string
  title: string
}

export interface SignupSubmission {
  user: {
    firstName: string
    lastName: string
    email: string
    password: string
  }
  payment?: {
    paypalId?: string
    stripeToken?: Token
    coupon?: string
  }
}

type DonationResponse = Pick<PaymentIntent, "client_secret" | "status">

export interface SignupResponse {
  token: string
  userId: number
  slug: string
  role: "admin" | "artist" | "member"
  reactivation: boolean
}

type ResponseWithError<R extends object> = Promise<{ error: false | request.HTTPError } & R>

const api = {
  async fetchCoupon(code: string): ResponseWithError<{ coupon: CouponResponse }> {
    let req = RequestBuilder.get(`/check_coupon?payment[coupon]=${code}`)
      .exchangingJson()
      .preventingConcurrent()
      .authenticated()
      .build()

    const { error, body } = await req.send()

    const coupon = error ? null : camelcaseKeys(body, { deep: true })

    return { error, coupon }
  },

  async donate(
    donationSubmission: DonationSubmission
  ): ResponseWithError<{ donationResult: DonationResponse }> {
    let req = RequestBuilder.post(`/donate`).exchangingJson().preventingConcurrent().build()

    const { error, body } = await req.send(snakecaseKeys(donationSubmission))

    const donationResult = error ? null : body

    return { error, donationResult }
  },

  async fetchSection(slug: string): ResponseWithError<{ section: SectionResponse }> {
    let req = RequestBuilder.get(`/sections/${slug}`).exchangingJson().preventingConcurrent().build()

    const { error, body } = await req.send()

    const { section } = error ? null : body

    return { error, section }
  },

  async signup(signupSubmission: SignupSubmission): ResponseWithError<{ user: SignupResponse }> {
    let req = RequestBuilder.post("/signup").exchangingJson().preventingConcurrent().build()

    const { error, body } = await req.send(
      snakecaseKeys({
        user: signupSubmission.user,
        ...(signupSubmission.payment
          ? {
              payment: {
                token: signupSubmission.payment.stripeToken?.id,
                cardType: signupSubmission.payment.stripeToken?.card?.brand,
                last4: signupSubmission.payment.stripeToken?.card?.last4,
                coupon: signupSubmission.payment.coupon,
                paypalId: signupSubmission.payment.paypalId,
              },
            }
          : {}),
      })
    )

    return { error, user: error ? null : body }
  },
}

export default api
