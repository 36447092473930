import { gql } from "@apollo/client"

export const SavedArtistQuery = gql`
  query SavedArtistQuery($params: SearchParams, $id: ID!) {
    savedArtists(params: $params, id: $id) {
      currentPage
      totalPages
      data {
        id
        artist {
          slug
          name
          livesAndWorks
          graduations {
            year
            school {
              name
            }
          }
          affiliations {
            organization {
              name
            }
          }
          profileImage {
            medium
          }
          formats {
            name
          }
        }
      }
    }
  }
`

export const ArtistDetailQuery = gql`
  query ArtistDetailQuery($slug: String!, $userId: ID) {
    artist(slug: $slug, userId: $userId) {
      slug
      name
      genderPronoun
      livesAndWorks
      graduations {
        year
        degreeType
        school {
          name
          city
          state {
            short
          }
          country {
            name
          }
        }
      }
      affiliations {
        organization {
          name
          city
          state {
            short
          }
          country {
            name
          }
        }
      }
      birthYear
      hometown
      website
      contactEmail
      cv
      statement
      artworks(publishedOnly: true) {
        published
      }
      savedByUser(userId: $userId)
    }
  }
`

export const UpdateSavedArtist = gql`
  mutation UpdateSavedArtist($userId: ID!, $slug: String!) {
    updateSavedArtist(userId: $userId, slug: $slug) {
      id
    }
  }
`

export const UpdateUser = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $billingAddress: String
    $vatId: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      billingAddress: $billingAddress
      vatId: $vatId
    ) {
      user {
        id
        firstName
        lastName
        email
        billingAddress
        vatId
      }
    }
  }
`

export const UpdateBillingInformation = gql`
  mutation UpdateBillingInformation($id: ID!, $billingAddress: String, $vatId: String) {
    updateUser(id: $id, billingAddress: $billingAddress, vatId: $vatId) {
      user {
        id
        billingAddress
        vatId
      }
    }
  }
`

export const PersonalSettingsQuery = gql`
  query PersonalSettingsQuery($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      billingAddress
      vatId
    }
  }
`

export const BillingSettingsQuery = gql`
  query PersonalSettingsQuery($id: ID!) {
    user(id: $id) {
      billingAddress
      vatId
    }
  }
`

export const UpdateUserProfile = gql`
  mutation UpdateUser(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $livesAndWorks: String!
    $livesAndWorksPoint: String!
    $contactEmail: String!
    $genderPronoun: String!
    $formats: [String!]
    $categories: [String!]
    $birthYear: String
    $hometown: String
    $website: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      livesAndWorks: $livesAndWorks
      livesAndWorksPoint: $livesAndWorksPoint
      contactEmail: $contactEmail
      genderPronoun: $genderPronoun
      formats: $formats
      categories: $categories
      birthYear: $birthYear
      hometown: $hometown
      website: $website
    ) {
      user {
        id
        formats {
          id
        }
        categories {
          id
        }
        firstName
        lastName
        livesAndWorks
        contactEmail
        billingAddress
        vatId
        genderPronoun
        birthYear
        hometown
        website
      }
    }
  }
`

export const LatestInvoicesQuery = gql`
  query LatestInvoicesQuery($id: ID!) {
    user(id: $id) {
      latestInvoices {
        id
        number
        date
        total
        url
      }
    }
  }
`

export const StatementQuery = gql`
  query StatementWrapperQuery($id: ID!) {
    user(id: $id) {
      id
      statement
    }
  }
`

export const UpdateUserStatement = gql`
  mutation UpdateUserStatement($id: ID!, $statement: String!) {
    updateUser(id: $id, statement: $statement) {
      user {
        id
        statement
      }
    }
  }
`

export const UpdateUserCV = gql`
  mutation UpdateUserCV($id: ID!, $cv: String!) {
    updateUser(id: $id, cv: $cv) {
      user {
        id
        cv
      }
    }
  }
`
