import { snakeKeys, camelKeys, serializeForm, checkForCoupon } from "../utils.js"
import ApiClient from "../ApiClient.js"

export function login(form, cb) {
  const data = snakeKeys(serializeForm(form))

  ApiClient.post("/login")
    .send(data)
    .end((err, response) => {
      cb({ err, response })
    })
}

export function validateCredentials(form, cb) {
  const data = serializeForm(form)
  const snakeData = snakeKeys(data)
  const credentials = (({ email, password }) => ({ email, password }))(snakeData)

  ApiClient.post("/validate_credentials")
    .send(credentials)
    .end((err, response) => {
      cb({ err, response }, data)
    })
}

export function cancelAccount(cb) {
  ApiClient.post(`/deactivate_account`)
    .send()
    .end((err, response) => {
      cb({ err, response })
    })
}

export function forgotPassword(form, cb) {
  ApiClient.post(`/forgot_password`)
    .send(serializeForm(form))
    .end((err, response) => {
      cb({ err, response })
    })
}

export function resetPassword(form, token, cb) {
  ApiClient.post(`/reset_password`)
    .send({ token })
    .send(serializeForm(form))
    .end((err, response) => {
      cb({ err, response })
    })
}
