import styled from "styled-components"
import { globalStylesCss } from "@/ui/styles/GlobalStyles"

const Link = styled.a`
  ${globalStylesCss};

  text-decoration: none;
  cursor: pointer;
  color: var(--font-color-link);
  font-size: inherit;

  &:hover {
    text-decoration: underline;
  }
`

export default Link
