import { types } from "../actions"

const auth = (
  state = {
    openLoginModal: false,
  },
  { type }
) => {
  switch (type) {
    case types.TOGGLE_LOGIN_MODAL: {
      return {
        ...state,
        openLoginModal: !state.openLoginModal,
      }
    }
    case types.CLOSE_LOGIN_MODAL: {
      return {
        ...state,
        openLoginModal: false,
      }
    }
    default: {
      return state
    }
  }
}

export default auth
