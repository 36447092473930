import React, { useState } from "react"
import classNames from "classnames/bind"
import styles from "./artist-update-payment-method"
import PaymentForm from "../../nextgen/payments/PaymentForm"
import { connect } from "react-redux"
import { gql, useMutation } from "@apollo/client"
import analytics from "../../nextgen/analytics"
import UserSession from "../../nextgen/userSession"

const cx = classNames.bind(styles)

const ArtistUpdatePaymentMethod = ({
  cardType,
  last4,
  isPaypal,
  onPaymentMethodUpdated,
  userId,
  dispatchOnReactivationSuccess,
}) => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!isOpen)

  const UpdatePaymentMethod = gql`
    mutation UpdatePaymentMethod(
      $id: ID!
      $token: String
      $cardType: String
      $last4: Int
      $paypalId: String
    ) {
      updatePaymentMethod(id: $id, token: $token, cardType: $cardType, last4: $last4, paypalId: $paypalId) {
        user {
          id
          paypalId
        }
        activePaymentMethod {
          paypalId
          last4
          cardType
        }
      }
    }
  `

  const accountSettingsQuery = gql`
    query MembershipSettingsQuery($id: ID!) {
      user(id: $id) {
        id
        createdAt
        paypalId
        activePaymentMethod {
          last4
          cardType
          paypalId
          coupon {
            id
            percentOff
            durationInMonths
            duration
            expiration
          }
        }
      }
    }
  `

  const [updatePaymentMethod] = useMutation(UpdatePaymentMethod)

  const finalizeReactivation = () => {
    analytics.emitEvent("Payment Method Updated")

    dispatchOnReactivationSuccess()
    UserSession.set("role", "artist")
    onPaymentMethodUpdated()
  }

  const handleNewStripePaymentMethod = async (token) => {
    await updatePaymentMethod({
      variables: {
        id: userId,
        token: token.id,
        cardType: token.card?.brand,
        last4: parseInt(token.card?.last4 || ""),
        paypalId: null,
      },
      refetchQueries: [
        {
          query: accountSettingsQuery,
          variables: { id: userId },
        },
      ],
    })

    return finalizeReactivation
  }

  const handleNewPaypalPaymentMethod = async (subscriptionId) => {
    await updatePaymentMethod({
      variables: { id: userId, token: null, cardType: null, last4: null, paypalId: subscriptionId },
      refetchQueries: [
        {
          query: accountSettingsQuery,
          variables: { id: userId },
        },
      ],
    })

    return finalizeReactivation
  }

  return (
    <div className={cx("field")}>
      <div className={cx("label")}>Payment Method</div>
      <div className={cx("info-container")}>
        <div className={cx("info")}>{isPaypal ? "PayPal" : `${cardType} Ending in ${last4}`}</div>
        <button
          className={cx(["btn", "btn-gray", "button"])}
          onClick={(e) => {
            e.preventDefault()
            toggleOpen()
          }}
        >
          {isOpen ? "Close" : "Update"}
        </button>
        {isOpen && (
          <div className={cx("payment-form")}>
            <PaymentForm
              buttonSize={0} // Small
              submitMessage="Update"
              onPayPalSubscriptionCreated={handleNewPaypalPaymentMethod}
              onCreditCardTokenAcquired={handleNewStripePaymentMethod}
            />
          </div>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ session }) => {
  const { userId } = session

  return { userId }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchOnReactivationSuccess: () => {
    dispatch({ type: "SET_AS_ARTIST", payload: {} })
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ArtistUpdatePaymentMethod)
